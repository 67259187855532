import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatOptionSelectionChange, MatSelectChange, MatSnackBar } from '@angular/material';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { SpinnerLoadingComponent } from '../spinner-loading/spinner-loading.component';
import { IUser, IUserObject } from '../_models';
import { Region } from '../_models/region';
import { Data, GlobalSettings, RestProvider } from '../_providers';
import { AuthenticationService } from '../_services';
import { AuditListDatasource } from './audit-list.datasource';


@Component({
  selector: 'app-audit-list',
  templateUrl: './audit-list.component.html',
  styleUrls: ['./audit-list.component.css']
})
export class AuditListComponent implements OnInit, OnDestroy {

  // For Unsubscribing
  private _destroy$ = new Subject<void>();

  title = 'Audit App';
  imagePrefix: string = environment.imagePrefix;
  myUrl: number;
  public displayClass = 'top';
  auditYears: number[] = [];

  // displayedColumns = [
  //   'name',
  //   'submissionJanPercentage',
  //   'submissionFebPercentage',
  //   'submissionMarPercentage',
  //   'submissionQ1Percentage',
  //   'submissionAprPercentage',
  //   'submissionMayPercentage',
  //   'submissionJunPercentage',
  //   'submissionQ2Percentage',
  //   'submissionB1Percentage',
  //   'submissionJulPercentage',
  //   'submissionAugPercentage',
  //   'submissionSepPercentage',
  //   'submissionQ3Percentage',
  //   'submissionOctPercentage',
  //   'submissionNovPercentage',
  //   'submissionDecPercentage',
  //   'submissionQ4Percentage',
  //   'submissionB2Percentage',
  //   'submissionAnnualPercentage',
  //   'rankYTD',
  //   'legalYTD',
  //   'scoreAdjust',
  //   'scoreTYD'
  // ];
  // displayedColumns = [];
  displayedColumns = [
    "name",
    "Jan",
    "Feb",
    "Mar",
    "Quarter 1",
    "Apr",
    "May",
    "Jun",
    "Quarter 2",
    "Bi-annual 1",
    "Jul",
    "Aug",
    "Sep",
    "Quarter 3",
    "Oct",
    "Nov",
    "Dec",
    "Quarter 4",
    "Bi-annual 2",
    "Annual",
    'rankYTD',
    'legalYTD',
    'scoreAdjust',
    'scoreTYD'
  ];
  displayedColumnsMap = {
    "name": "Name",
    "submissionJanPercentage": "Jan",
    "submissionFebPercentage": "Feb",
    "submissionMarPercentage": "Mar",
    "submissionQuarter 1Percentage": "Qtr",
    "submissionAprPercentage": "Apr",
    "submissionMayPercentage": "May",
    "submissionJunPercentage": "Jun",
    "submissionQuarter 2Percentage": "Qtr2",
    "submissionBi-annual 1Percentage": "B1",
    "submissionJulPercentage": "Jul",
    "submissionAugPercentage": "Aug",
    "submissionSepPercentage": "Sep",
    "submissionQuarter 3Percentage": "Qtr3",
    "submissionOctPercentage": "Oct",
    "submissionNovPercentage": "Nov",
    "submissionDecPercentage": "Dec",
    "submissionQuarter 4Percentage": "Qtr4",
    "submissionBi-annual 2Percentage": "B2",
    "submissionAnnualPercentage": "A1",
    "rankYTD": "Rank YTD",
    "legalYTD": "YTD",
    "scoreAdjust": "Score Ad",
    "scoreTYD": "Score TYD"
  }
  

  dataSource: AuditListDatasource;

  YearList: Promise<number[]>;
  RegionList: Promise<any[]>;
  currentYear = NaN;
  currentRegion: Region | null = null;
  userDetail: IUserObject | null = null;

  public constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    public data: Data,
    public _rest: RestProvider,
    private globalSettings: GlobalSettings,
    private _authenticationService: AuthenticationService,
    public snackBar: MatSnackBar
  ) {
    const userDetail = this._authenticationService.userDetail$.value;
    this.userDetail = userDetail;

    this.YearList = _rest.getAuditYears().catch(() => []);
    this.RegionList = data.getRegionList(userDetail.regions).catch(() => []);

    this.globalSettings.isFullScreen$.pipe(takeUntil(this._destroy$))
      .subscribe(() => { this.navigate(); });

    // should come up with a not so low level method to tell component position
    router.events.pipe(takeUntil(this._destroy$)).subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.navigate();
        if (this.dataSource && !this.data.loadingAuditList.value) {
          this.dataSource.loadData();
          this.displayedColumns = this.dataSource.auditTableDisplayColumns;
        }
      }
    });

    // this code only runs when it is initialised so we can determine the components position from the url used to load it
    this.myUrl = (router.url.match(/\//g) || []).length;

    this.data.loadingAuditList$.pipe(takeUntil(this._destroy$)).subscribe((next) => {

      if (!next) {
        
        this.dataSource = new AuditListDatasource(this.data);
        this.dataSource.loadData();
        this.displayedColumns = this.dataSource.auditTableDisplayColumns;
      }
    });
  }

  navigate() {
    if ((this.router.url.match(/\//g) || []).length === this.myUrl) {
      this.displayClass = 'full-top';
    } else {
      this.displayClass = 'gone';
    }
  }

  ngOnInit() {
    const { regionID, year } = (this.route.params as any).value;
    // console.log("ngOnInit AuditListComponent", regionID, year,  "this.displayedColumns: ", this.displayedColumns);
    
    let lastRegionID = Number(regionID);
    let lastYear = Number(year);

    Promise.all([this.YearList, this.RegionList]).then(([years, regions]) => {
      this.auditYears = years;

      this.route.paramMap.subscribe(params => {
        const regionID = Number(params['params']['regionID']);
        const year = Number(params['params']['year']);
        // console.log("AuditListComponent", regionID, year);

        let shouldUpdate = false;

        // years
        if (years.length > 0) {
          if (years.includes(year)) {
            lastYear = year;
          } else {
            shouldUpdate = true;
            lastYear = years[0];
          }
        }

        // regions
        if (regions.length > 0) {
          if (regions.some((region) => region.id === regionID)) {
            lastRegionID = regionID;
          } else {
            shouldUpdate = true;
            lastRegionID = regions[0].id;
          }
        }
        // console.log("AuditListComponent", shouldUpdate);

        // shouldUpdate
        if (shouldUpdate) {
          this.router.navigate(['audits', lastRegionID, lastYear]);
          return;
        }
        // console.log("AuditListComponent", lastRegionID, lastYear);

        // valid values
        if (!isNaN(lastRegionID) && !isNaN(lastYear)) {
          this.genericRegionYearChange(lastRegionID, lastYear);
        }
      });
    });

    let dialogRef = null;
    this.data.loadingAuditList$.pipe(takeUntil(this._destroy$)).subscribe((loaded) => {
      if (loaded) {
        if (dialogRef == null) {
          dialogRef = this.dialog.open(SpinnerLoadingComponent, {
            disableClose: true,
            panelClass: 'spinner2',
          });
        }
      } else {
        if (dialogRef != null) {
          dialogRef.close();
          dialogRef = null;
        }
      }
    });
  }

  public onTap(auditId) {
    this.data.switchAudit(auditId);
    const nav = 'submission';
    // noinspection JSIgnoredPromiseFromCall // todo probably shouldn't ignore it
    this.router.navigate([nav], { relativeTo: this.route });
  }

  actionSelect(event: MatSelectChange, position: number) {
    switch (event.value) {
      case 'score100':
        this.dataSource.rowChangePercentages(position, true);
        this.dataSource.rowChangeYearToDate(position, false);
        break;
      case 'ytd':
        this.dataSource.rowChangeYearToDate(position, true);
        this.dataSource.rowChangePercentages(position, false);
        break;
      case 'score0':
        this.dataSource.rowChangeYearToDate(position, false);
        this.dataSource.rowChangePercentages(position, false);
        break;
    }
    event.source.writeValue(''); // rewrite value back to blank
  }

  actionSelectRegion(event: MatSelectChange) {
    switch (event.value) {
      case 'score100':
        this.dataSource.regionChangePercentages(true);
        this.dataSource.regionChangeYearToDate(false);
        break;
      case 'ytd':
        this.dataSource.regionChangeYearToDate(true);
        this.dataSource.regionChangePercentages(false);
        break;
      case 'score0':
        this.dataSource.regionChangePercentages(false);
        this.dataSource.regionChangeYearToDate(false);
        break;
    }
    event.source.writeValue(''); // rewrite value back to blank
  }

  regionChange(event: MatOptionSelectionChange) {
    if (event.isUserInput) {
      this.router.navigate(['/audits', event.source.value, this.data.currentYear], {
        relativeTo: this.route,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
    }
  }


  genericRegionYearChange(regionID: number, year: number) {
    this.data.getAuditList(regionID, year);
    // console.log('called region year change with ID: ' + regionID + ' and year: ' + year);
    this.data.currentRegionID = regionID;
    this.data.currentYear = year;
    
    this.currentRegion = this.data.regionList.find(x => x.id === regionID);
  }

  yearChange(event: MatOptionSelectionChange) {
    if (event.isUserInput) {
      this.router.navigate(['/audits', this.data.currentRegionID, event.source.value], {
        relativeTo: this.route,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
    }
  }


  hideBehind() {
    // console.log('scrolling');
    /*const obj = document.getElementById('obj');
     const leftSideOfTableContents = obj.getBoundingClientRect();
     console.log(leftSideOfTableContents.right);*/
    /*if (){
  
     }*/
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    console.log('AuditListComponent ngOnDestroy')
  }

}
